<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    @click:outside="onClose"
  >
    <template #activator="{ on: dialog }">
      <v-icon
        color="warning"
        small
        class="mx-1"
        @click="onShowDialog"
        v-on="{ ...dialog }"
        data-test="activate-button"
        :data-cy="`quotes-list-change-status-button-${quote.id}`"
      >
        mdi-state-machine
      </v-icon>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title data-test="customer-toolbar"
            >{{ quote.project_name }} - Status:
            <span class="text-capitalize">{{
              quote.status
            }}</span></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="9">
                <v-autocomplete
                  :items="quoteStatusList"
                  item-disabled="disable"
                  name="quoteStatus"
                  item-text="description"
                  item-value="description"
                  v-model="formQuoteStatus"
                  outlined
                  dense
                  return-object
                  class="subtitle-1"
                  data-test="quote-status"
                  @keydown.enter="saveHandler"
                >
                  <template slot="item" slot-scope="data">
                    <span class="text-capitalize" data-test="display-text">{{
                      data.item["description"]
                    }}</span>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <span class="text-capitalize">{{
                      data.item["description"]
                    }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  block
                  :disabled="!formQuoteStatus"
                  color="secondary"
                  @click="saveHandler"
                  data-test="save-button"
                  >Update</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "QuoteStatusModal",
  props: {
    quote: {
      type: Object,
      default: null,
    },
    quoteStatus: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      formQuoteStatus: null,
    };
  },
  computed: {
    quoteStatusList() {
      return this.quoteStatus
        .map((status) => ({
          ...status,
          disable: status.description === this.quote.status,
        }))
        .filter((element) => element.description !== "All");
    },
  },
  methods: {
    onShowDialog() {
      this.formQuoteStatus = this.quote ? this.quote.status : null;
    },
    saveHandler() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const actionPayload = {
        editMode: true,
        id: this.quote.id,
        data: {
          status: this.formQuoteStatus.description,
        },
      };
      this.$emit("onUpdateQuoteStatus", actionPayload);
      this.onClose();
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
